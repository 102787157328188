import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "allianzList"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "ovauto"
  }, [_c('div', {
    staticClass: "toolbar"
  }, [_c('div', {
    staticClass: "toolbar-item"
  }, [_c('div', {
    staticClass: "toolbar-item-content"
  }, [_c('small', {
    staticClass: "inl wz-tags noselect condensed"
  }, [_c('div', {
    staticClass: "wz-tag cirlce"
  }), _c('span', [_vm._v("leer")]), _c('br'), _c('div', {
    staticClass: "wz-tag cirlce _red"
  }), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'isOpen';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("Link offen")]), _c('br'), _c('div', {
    staticClass: "wz-tag cirlce _brown"
  }), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'isSentEmail';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("Link versendet")]), _c('br'), _c('div', {
    staticClass: "wz-tag cirlce _orange"
  }), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'fillByHvAt';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("HVW Daten erhalten")])]), _c('small', {
    staticClass: "inl wz-tags noselect condensed"
  }, [_c('div', {
    staticClass: "wz-tag cirlce _lblue"
  }), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'svUid';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("SV zugeteilt")]), _c('br'), _c('div', {
    staticClass: "wz-tag cirlce _green"
  }), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'isFilledBySv';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("SV Daten vorhanden")]), _c('br'), _c('div', {
    staticClass: "wz-tag cirlce _darkpink"
  }), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'isCompleted';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("Akt fertiggestellt")])])])])]), _c('div', {
    staticClass: "paginator ovauto"
  }, [_c('div', {
    staticClass: "wz-tag"
  }, [_c('span', {
    staticClass: "_redp"
  }, [_vm._v(_vm._s(_vm.filteredListLength))]), _vm._v("Akte auf"), _c('span', {
    staticClass: "_lbluep"
  }, [_vm._v(_vm._s(_vm.fPages.length))]), _vm._v("Seiten")]), _c('v-pagination', {
    attrs: {
      "fPages": _vm.fPages
    },
    model: {
      value: _vm.onpage,
      callback: function callback($$v) {
        _vm.onpage = _vm._n($$v);
      },
      expression: "onpage"
    }
  }), _c('div', [_c('div', {
    staticClass: "wz-tag"
  }, [_c('span', [_vm._v("Akte pro Seite:")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.iperpage = 10;
      }
    }
  }, [_vm._v("10")]), _c('div', {
    staticClass: "inl"
  }), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.iperpage = 30;
      }
    }
  }, [_vm._v("30")]), _c('div', {
    staticClass: "inl"
  }), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.iperpage = 100;
      }
    }
  }, [_vm._v("100")]), _c('div', {
    staticClass: "inl"
  }), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.iperpage = 250;
      }
    }
  }, [_vm._v("250")]), _c('div', {
    staticClass: "fr"
  }, [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.$modal.show('SetTableProps');
      }
    }
  }, [_vm._v("Tabelle anpassen")])])])])], 1)])]), _c('div', {
    staticClass: "card _less"
  }, [_vm._isAdmin || _vm._isInternSv || _vm._isSvPlus ? _c('span', [_c('button', {
    staticClass: "btn _small _grey",
    attrs: {
      "disabled": _vm.Selceted_Projects_Arr.length == 0
    },
    on: {
      "click": function click($event) {
        return _vm.$modal.show('MakeBatch');
      }
    }
  }, [_vm._v("Sammelaktionen (" + _vm._s(_vm.Selceted_Projects_Arr.length) + ")")]), _c('button', {
    staticClass: "btn _small _bordered",
    on: {
      "click": function click($event) {
        return _vm.selectSeeable();
      }
    }
  }, [_vm._v("Alle sel.")]), _c('button', {
    staticClass: "btn _small _bordered",
    on: {
      "click": function click($event) {
        return _vm.deselectAll();
      }
    }
  }, [_vm._v("Keine sel.")]), _c('span', [_vm._v("  ·  ")]), _vm._isAdmin ? _c('span', [_c('v-popover', {
    staticClass: "inl _hover",
    attrs: {
      "offset": "13",
      "placement": "bottom"
    }
  }, [_c('div', {
    staticClass: "btn _small _green"
  }, [_vm._v("  Export  ")]), _c('template', {
    staticClass: "new-block-tt",
    slot: "popover"
  }, [_c('ul', [_c('li', {
    staticClass: "choose-block-li padding _small"
  }, [_c('a', {
    directives: [{
      name: "close-popover",
      rawName: "v-close-popover"
    }],
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.$modal.show('CSVExportModal');
      }
    }
  }, [_vm._v("XLSX/CSV Export")])]), _c('li', {
    staticClass: "choose-block-li padding _small"
  }, [_c('a', {
    directives: [{
      name: "close-popover",
      rawName: "v-close-popover"
    }],
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.fetchCompletedProjects(true);
      }
    }
  }, [_vm._v("Abgeschlossene Akte (Z1,T1)")])]), _c('li', {
    staticClass: "choose-block-li padding _small"
  }, [_c('a', {
    directives: [{
      name: "close-popover",
      rawName: "v-close-popover"
    }],
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.fetchCompletedProjects(false);
      }
    }
  }, [_vm._v("Abgeschlossene Akte (nur Z1)")])])])])], 2)], 1) : _c('span', [_c('button', {
    staticClass: "btn _small _green",
    on: {
      "click": function click($event) {
        return _vm.$modal.show('CSVExportModal');
      }
    }
  }, [_vm._v("XLSX/CSV Export")])]), _c('span', [_vm._v("  ·  ")])]) : _vm._e(), _c('span', [_c('v-popover', {
    staticClass: "inl _hover",
    attrs: {
      "offset": "13",
      "placement": "bottom"
    }
  }, [_c('div', {
    staticClass: "btn _small _green"
  }, [_vm._v("  Filter  ")]), _c('template', {
    staticClass: "new-block-tt",
    slot: "popover"
  }, [_c('ul', [!_vm._currentUser.loadOnlyMy && (_vm._isAdmin || _vm._isInternSv || _vm._isSvPlus) ? _c('li', {
    staticClass: "choose-block-li padding _small"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "nur meine anzeigen"
    },
    model: {
      value: _vm.filterMyAkte,
      callback: function callback($$v) {
        _vm.filterMyAkte = $$v;
      },
      expression: "filterMyAkte"
    }
  })], 1) : _vm._e(), !_vm._currentUser.loadOnlyMy && (_vm._isAdmin || _vm._isInternSv || _vm._isSvPlus) ? _c('li', {
    staticClass: "choose-block-li padding _small"
  }, [_c('div', {
    staticClass: "inl"
  }, [_vm._v("  •")]), _c('v-checkbox', {
    attrs: {
      "label": "(alleiniger SV1)",
      "disabled": !_vm.filterMyAkte
    },
    model: {
      value: _vm.filterMyAkteSv1,
      callback: function callback($$v) {
        _vm.filterMyAkteSv1 = $$v;
      },
      expression: "filterMyAkteSv1"
    }
  })], 1) : _vm._e(), _vm._isAdmin || _vm._isInternSv || _vm._isSvPlus ? _c('li', {
    staticClass: "choose-block-li padding _small"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Z1 ausblenden"
    },
    model: {
      value: _vm.filterStatus.z1,
      callback: function callback($$v) {
        _vm.$set(_vm.filterStatus, "z1", $$v);
      },
      expression: "filterStatus.z1"
    }
  })], 1) : _vm._e(), _c('li', {
    staticClass: "choose-block-li padding _small"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Urgenz/wichtig"
    },
    model: {
      value: _vm.isShowOnlyUrgent,
      callback: function callback($$v) {
        _vm.isShowOnlyUrgent = $$v;
      },
      expression: "isShowOnlyUrgent"
    }
  })], 1), _vm._isAdmin ? _c('li', {
    staticClass: "choose-block-li padding _small"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Nur Sichtbare"
    },
    model: {
      value: _vm.isShowOnlyVisible,
      callback: function callback($$v) {
        _vm.isShowOnlyVisible = $$v;
      },
      expression: "isShowOnlyVisible"
    }
  })], 1) : _vm._e(), _c('li', {
    staticClass: "choose-block-li padding _small"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "M2 einblenden"
    },
    model: {
      value: _vm.filterStatus.m2,
      callback: function callback($$v) {
        _vm.$set(_vm.filterStatus, "m2", $$v);
      },
      expression: "filterStatus.m2"
    }
  })], 1), _c('li', {
    staticClass: "choose-block-li padding _small"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "M3 einblenden"
    },
    model: {
      value: _vm.filterStatus.m3,
      callback: function callback($$v) {
        _vm.$set(_vm.filterStatus, "m3", $$v);
      },
      expression: "filterStatus.m3"
    }
  })], 1), _c('li', {
    staticClass: "choose-block-li padding _small"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "H5 einblenden"
    },
    model: {
      value: _vm.filterStatus.h5,
      callback: function callback($$v) {
        _vm.$set(_vm.filterStatus, "h5", $$v);
      },
      expression: "filterStatus.h5"
    }
  })], 1), _c('li', {
    staticClass: "choose-block-li padding _small"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "'NUR' Denkmalschutz"
    },
    model: {
      value: _vm.isShowOnlyDs,
      callback: function callback($$v) {
        _vm.isShowOnlyDs = $$v;
      },
      expression: "isShowOnlyDs"
    }
  })], 1), _c('li', {
    staticClass: "choose-block-li padding _small"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "'NUR' Mehraufwand"
    },
    model: {
      value: _vm.isShowOnlyMa,
      callback: function callback($$v) {
        _vm.isShowOnlyMa = $$v;
      },
      expression: "isShowOnlyMa"
    }
  })], 1), _c('li', {
    staticClass: "choose-block-li padding _small"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "neu in 2023"
    },
    model: {
      value: _vm.is2023Projects,
      callback: function callback($$v) {
        _vm.is2023Projects = $$v;
      },
      expression: "is2023Projects"
    }
  })], 1), _c('li', {
    staticClass: "choose-block-li padding _small"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "MFH – S.Florian Projekt"
    },
    model: {
      value: _vm.isMfhSFP,
      callback: function callback($$v) {
        _vm.isMfhSFP = $$v;
      },
      expression: "isMfhSFP"
    }
  })], 1)])])], 2)], 1), _vm.globalSearch || _vm.$_size(_vm.extraAllianzFilterFields) > 0 ? _c('div', {
    staticClass: "tac"
  }, [_c('small', {
    staticClass: "tac"
  }, [_vm.globalSearch ? _c('span', {
    staticClass: "_redp bold"
  }, [_vm._v("Filter nach: \"" + _vm._s(_vm.globalSearch) + "\"")]) : _vm._e(), _c('span', [_vm._v("  ·  ")]), _vm.$_size(_vm.extraAllianzFilterFields) > 0 ? _c('span', {
    staticClass: "_redp bold"
  }, [_vm._v("Spalten-Filter: " + _vm._s(_vm.$_.values(_vm.extraAllianzFilterFields)))]) : _vm._e()])]) : _vm._e()]), _c('div', {
    staticClass: "card"
  }, [!_vm.fbDataLoaded ? _c('div', {
    staticClass: "card-content"
  }, [_vm._m(0)]) : _vm.fbDataLoaded ? _c('div', {
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "ovauto"
  }, [!_vm._currentUser.loadAllianz ? _c('div', {
    staticClass: "padding tac"
  }, [_c('h4', [_vm._v("Keine Daten geladen")]), _c('p', [_vm._v("Es werden jetzt keine Allianz Daten geladen.")]), _c('p', [_vm._v("In den Einstellungen kann angeben werden, was geladen werden soll.")])]) : _vm._currentUser.loadAllianz ? _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', {
    staticClass: "whitespace-pre"
  }, [_c('th', {
    attrs: {
      "valign": "top"
    }
  }, [!_vm.isShowingExtraFilter ? _c('span', {
    on: {
      "click": function click($event) {
        _vm.isShowingExtraFilter = !_vm.isShowingExtraFilter;
      }
    }
  }, [_vm._m(1)]) : _vm._e(), _vm.isShowingExtraFilter ? _c('span', {
    on: {
      "click": function click($event) {
        _vm.isShowingExtraFilter = !_vm.isShowingExtraFilter;
      }
    }
  }, [_vm._m(2)]) : _vm._e()]), _vm._showInTable.status ? _c('th', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("Status"), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'nr';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("Nr.")]), _c('span', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.htmlToolTipForStatus,
      expression: "htmlToolTipForStatus"
    }]
  }, [_vm._v("?")])]) : _vm._e(), _vm._showInTable.polnr ? _c('th', {
    attrs: {
      "valign": "top"
    }
  }, [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'polnr';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("Pol.Nr.")])]) : _vm._e(), _vm._showInTable.status ? _c('th', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("Status"), _vm.isShowingExtraFilter ? _c('div', {
    staticClass: "extrafilter"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy.trim",
      value: _vm.extraAllianzFilterFields.resolvedonlystatus,
      expression: "extraAllianzFilterFields.resolvedonlystatus",
      modifiers: {
        "lazy": true,
        "trim": true
      }
    }, {
      name: "debounce",
      rawName: "v-debounce",
      value: 350,
      expression: "350"
    }],
    staticClass: "_verysmall",
    attrs: {
      "type": "text",
      "placeholder": "Status"
    },
    domProps: {
      "value": _vm.extraAllianzFilterFields.resolvedonlystatus
    },
    on: {
      "change": function change($event) {
        _vm.$set(_vm.extraAllianzFilterFields, "resolvedonlystatus", $event.target.value.trim());
      },
      "blur": function blur($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _c('span', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'Für Mehrfach-Filter, mit Beistrich trennen: Bsp: e1,h4 <br> Nur "genaue" Ergebnisse: <br> "S1" findet nur "S1", nicht "S13". <br> Mit Beistrich am Ende: <br> "S1," findet auch "S13"',
      expression: "'Für Mehrfach-Filter, mit Beistrich trennen: Bsp: e1,h4 <br> Nur \"genaue\" Ergebnisse: <br> \"S1\" findet nur \"S1\", nicht \"S13\". <br> Mit Beistrich am Ende: <br> \"S1,\" findet auch \"S13\"'"
    }],
    staticClass: "bold"
  }, [_vm._v("?")])]) : _vm._e()]) : _vm._e(), _vm._isAdmin && _vm._showInTable.isCustomPdf ? _c('th', {
    attrs: {
      "valign": "top"
    }
  }, [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'isCustomPdf';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("Eigenes PDF")])]) : _vm._e(), _vm._isAdmin && _vm._showInTable.is2023Projects ? _c('th', {
    attrs: {
      "valign": "top"
    }
  }, [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'is2023Projects';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("neu ab 2023 ")])]) : _vm._e(), _vm._isAdmin && _vm._showInTable.paidStatus ? _c('th', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("Bezahlt")]) : _vm._e(), _vm._isAdmin && _vm._showInTable.efforts ? _c('th', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("DS/Mehraufwand")]) : _vm._e(), _vm._showInTable.svStatus ? _c('th', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("SVStatus")]) : _vm._e(), _vm._showInTable.adresse_risiko ? _c('th', {
    attrs: {
      "valign": "top"
    }
  }, [_c('span', [_vm._v("Risiko:")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'name';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("Name")]), _c('span', [_vm._v("/")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'plz_risiko';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("PLZ")]), _c('span', [_vm._v("/")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'ortschaft_risiko';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("ORT")]), _vm.isShowingExtraFilter ? _c('div', {
    staticClass: "extrafilter"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy.trim",
      value: _vm.extraAllianzFilterFields.plz_risiko,
      expression: "extraAllianzFilterFields.plz_risiko",
      modifiers: {
        "lazy": true,
        "trim": true
      }
    }, {
      name: "debounce",
      rawName: "v-debounce",
      value: 350,
      expression: "350"
    }],
    staticClass: "_verysmall",
    attrs: {
      "type": "text",
      "placeholder": "PLZ"
    },
    domProps: {
      "value": _vm.extraAllianzFilterFields.plz_risiko
    },
    on: {
      "change": function change($event) {
        _vm.$set(_vm.extraAllianzFilterFields, "plz_risiko", $event.target.value.trim());
      },
      "blur": function blur($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _c('span', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'PLZ gilt auch für Bundesländer mit der ersten PLZ-Nummer: Bsp: 8 für Steiermark',
      expression: "'PLZ gilt auch für Bundesländer mit der ersten PLZ-Nummer: Bsp: 8 für Steiermark'"
    }],
    staticClass: "bold"
  }, [_vm._v("?")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy.trim",
      value: _vm.extraAllianzFilterFields.ortschaft_risiko,
      expression: "extraAllianzFilterFields.ortschaft_risiko",
      modifiers: {
        "lazy": true,
        "trim": true
      }
    }, {
      name: "debounce",
      rawName: "v-debounce",
      value: 350,
      expression: "350"
    }],
    staticClass: "_small",
    attrs: {
      "type": "text",
      "placeholder": "Ort"
    },
    domProps: {
      "value": _vm.extraAllianzFilterFields.ortschaft_risiko
    },
    on: {
      "change": function change($event) {
        _vm.$set(_vm.extraAllianzFilterFields, "ortschaft_risiko", $event.target.value.trim());
      },
      "blur": function blur($event) {
        return _vm.$forceUpdate();
      }
    }
  })]) : _vm._e()]) : _vm._e(), _vm._showInTable.contact_allianz ? _c('th', {
    attrs: {
      "valign": "top"
    }
  }, [_c('span', [_vm._v("A.Ktk:")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'contact_name';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("Name")]), _c('span', [_vm._v("/")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'contact_phone';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("Tel")]), _c('span', [_vm._v("/")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'contact_email';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("Email")])]) : _vm._e(), _vm._showInTable.hvUid ? _c('th', {
    attrs: {
      "valign": "top"
    }
  }, [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'hvUid';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("HV")]), _vm.isShowingExtraFilter ? _c('div', {
    staticClass: "extrafilter"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy.trim",
      value: _vm.extraAllianzFilterFields.resolvedhv,
      expression: "extraAllianzFilterFields.resolvedhv",
      modifiers: {
        "lazy": true,
        "trim": true
      }
    }, {
      name: "debounce",
      rawName: "v-debounce",
      value: 350,
      expression: "350"
    }],
    staticClass: "_small",
    attrs: {
      "type": "text",
      "placeholder": "HV"
    },
    domProps: {
      "value": _vm.extraAllianzFilterFields.resolvedhv
    },
    on: {
      "change": function change($event) {
        _vm.$set(_vm.extraAllianzFilterFields, "resolvedhv", $event.target.value.trim());
      },
      "blur": function blur($event) {
        return _vm.$forceUpdate();
      }
    }
  })]) : _vm._e()]) : _vm._e(), _vm._showInTable.fillByHvAt ? _c('th', {
    attrs: {
      "valign": "top"
    }
  }, [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'fillByHvAt';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("von HV befüllt")])]) : _vm._e(), _vm._showInTable.contacts ? _c('th', {
    attrs: {
      "valign": "top"
    }
  }, [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'makler';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("Makler")])]) : _vm._e(), _vm._showInTable.contacts ? _c('th', {
    attrs: {
      "valign": "top"
    }
  }, [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'eigen';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("Eigentümer")])]) : _vm._e(), _vm._showInTable.contacts ? _c('th', {
    attrs: {
      "valign": "top"
    }
  }, [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'besichtigung';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("Bes.Kontakt")])]) : _vm._e(), _vm._showInTable.vs_feuer ? _c('th', {
    staticClass: "nobreak",
    attrs: {
      "valign": "top"
    }
  }, [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'vs_feuer';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_c('span', [_vm._v("VSumme")])]), _c('br'), _c('span', [_vm._v("(alt / neu)")])]) : _vm._e(), _vm._showInTable.svuid ? _c('th', {
    attrs: {
      "valign": "top"
    }
  }, [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'resolvedsv';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("SV")]), _vm.isShowingExtraFilter ? _c('div', {
    staticClass: "extrafilter"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy.trim",
      value: _vm.extraAllianzFilterFields.resolvedsv,
      expression: "extraAllianzFilterFields.resolvedsv",
      modifiers: {
        "lazy": true,
        "trim": true
      }
    }, {
      name: "debounce",
      rawName: "v-debounce",
      value: 350,
      expression: "350"
    }],
    staticClass: "_small",
    attrs: {
      "type": "text",
      "placeholder": "SV"
    },
    domProps: {
      "value": _vm.extraAllianzFilterFields.resolvedsv
    },
    on: {
      "change": function change($event) {
        _vm.$set(_vm.extraAllianzFilterFields, "resolvedsv", $event.target.value.trim());
      },
      "blur": function blur($event) {
        return _vm.$forceUpdate();
      }
    }
  })]) : _vm._e()]) : _vm._e(), _vm._showInTable.modified ? _c('th', {
    attrs: {
      "valign": "top"
    }
  }, [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'modified';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("bearbeitet")])]) : _vm._e(), _vm._showInTable.reportedDataModified ? _c('th', {
    attrs: {
      "valign": "top"
    }
  }, [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.pr_sorter = 'reportedDataModified';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("abgeschloßen")])]) : _vm._e()])]), _vm.filteredListLength > 0 ? _c('tbody', _vm._l(_vm.fPages[_vm.onpage - 1], function (item, id) {
    return _c('tr', {
      staticClass: "allianz_list_item",
      class: _vm.aktClass(item, id),
      on: {
        "click": function click($event) {
          return _vm.handleTRClick($event, item.id);
        }
      }
    }, [_c('td', [_vm._isAdmin || _vm._isInternSv || _vm._isSvPlus ? _c('span', [_c('v-checkbox', {
      attrs: {
        "item": item.id
      },
      model: {
        value: _vm.Selceted_Projects_Arr,
        callback: function callback($$v) {
          _vm.Selceted_Projects_Arr = $$v;
        },
        expression: "Selceted_Projects_Arr"
      }
    })], 1) : _vm._e()]), _vm._showInTable.status ? _c('td', {
      staticClass: "statustd"
    }, [_c('StatusLights', {
      attrs: {
        "item": item
      }
    }), _c('div', {
      staticClass: "wz-tags"
    }, [_vm._v(_vm._s(item.consenseNr))]), _c('small', [_vm._v(_vm._s(_vm.$dayjs(item.modified).format('DD.MM.YY/HH:mm')))])], 1) : _vm._e(), _vm._showInTable.polnr ? _c('td', {
      staticClass: "nobreak"
    }, [_c('router-link', {
      staticClass: "bold link",
      attrs: {
        "to": '/allianz/list/' + item.id
      }
    }, [_c('span', {
      staticClass: "polnr"
    }, [_vm._v(_vm._s(item.polnr))])]), _c('br'), _c('small', [_vm._v(_vm._s(item.consenseNr))])], 1) : _vm._e(), _vm._showInTable.status ? _c('td', {
      staticClass: "nobreak"
    }, [_c('span', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: {
          content: (_vm.aktStatus[item.status] || {}).name,
          placement: 'top',
          offset: 10,
          delay: {
            show: 200,
            hide: 300
          }
        },
        expression: "{content: (aktStatus[item.status]||{}).name ,placement: 'top', offset:10, delay: {show: 200,hide: 300}}"
      }],
      staticClass: "bold"
    }, [_vm._v(_vm._s((_vm.aktStatus[item.status] || {}).icon))]), _vm._fbHausverwaltungen[item.hvUid] ? _c('span', [_vm._v("/"), _c('span', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: {
          content: (_vm.hvStatus[(_vm._fbHausverwaltungen[item.hvUid] || {}).status || 700] || {}).name,
          placement: 'right-end',
          offset: 10,
          delay: {
            show: 200,
            hide: 300
          }
        },
        expression: "{content: (hvStatus[((_fbHausverwaltungen[item.hvUid]||{}).status||700)]||{}).name ,placement: 'right-end', offset:10, delay: {show: 200,hide: 300}}"
      }],
      staticClass: "lightblu bold"
    }, [_vm._v(_vm._s((_vm.hvStatus[(_vm._fbHausverwaltungen[item.hvUid] || {}).status || 700] || {}).icon))])]) : _vm._e(), item.isDenkmalschutz ? _c('span', [_c('br'), _c('span', {
      staticClass: "color-mint bold"
    }, [_vm._v("(DS)")])]) : _vm._e()]) : _vm._e(), _vm._isAdmin && _vm._showInTable.isCustomPdf ? _c('td', {
      attrs: {
        "valign": "top"
      }
    }, [_vm._v(_vm._s(item.isCustomPdf ? "JA" : "-"))]) : _vm._e(), _vm._isAdmin && _vm._showInTable.is2023Projects ? _c('td', {
      attrs: {
        "valign": "top"
      }
    }, [_vm._v(_vm._s(item.is2023Projects ? "JA" : "-"))]) : _vm._e(), _vm._isAdmin && _vm._showInTable.paidStatus ? _c('td', {
      attrs: {
        "valign": "top"
      }
    }, [_c('span', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: {
          content: _vm.calcedZ1Status(item),
          placement: 'top',
          offset: 10,
          delay: {
            show: 200,
            hide: 300
          }
        },
        expression: "{content: calcedZ1Status(item),placement: 'top', offset:10, delay: {show: 200,hide: 300}}"
      }]
    }, [_c('small', {
      domProps: {
        "innerHTML": _vm._s(_vm.billingDataStatus(item))
      }
    })])]) : _vm._e(), _vm._isAdmin && _vm._showInTable.efforts ? _c('td', {
      attrs: {
        "valign": "top"
      }
    }, [_vm._v(_vm._s(item.dsmaEffort))]) : _vm._e(), _vm._showInTable.svStatus ? _c('td', [_c('span', [_vm._v(_vm._s(_vm.calculateSvDataStatus(item)) + "/3")])]) : _vm._e(), _vm._showInTable.adresse_risiko ? _c('td', {
      staticClass: "nobreak"
    }, [_c('span', [_vm._v(_vm._s(item.vorname)), (item.name || '').length > 24 ? _c('span', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: {
          content: item.name,
          placement: 'top',
          offset: 10,
          delay: {
            show: 200,
            hide: 300
          }
        },
        expression: "{content: item.name ,placement: 'top', offset:10, delay: {show: 200,hide: 300}}"
      }]
    }, [_vm._v(_vm._s(item.name.substring(0, 24)) + "...")]) : _c('span', [_vm._v(_vm._s(item.name))])]), _c('br'), _c('span', [_vm._v(_vm._s(item.adresse_risiko) + _vm._s(item.adresse_risiko_zusatz))]), _c('br'), _c('small', {
      staticClass: "bold"
    }, [_vm._v(_vm._s(item.plz_risiko) + " " + _vm._s(item.ortschaft_risiko))])]) : _vm._e(), _vm._showInTable.contact_allianz ? _c('td', {
      staticClass: "nobreak"
    }, [(item.contact_name || '').length > 24 ? _c('span', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: {
          content: item.contact_name,
          placement: 'top',
          offset: 10,
          delay: {
            show: 200,
            hide: 300
          }
        },
        expression: "{content: item.contact_name ,placement: 'top', offset:10, delay: {show: 200,hide: 300}}"
      }]
    }, [_vm._v(_vm._s(item.contact_name.substring(0, 24)) + "...")]) : _c('span', [_vm._v(_vm._s(item.contact_name))]), _c('br'), _c('small', {
      staticClass: "bold"
    }, [_vm._v(_vm._s(item.contact_email))]), _c('br'), _c('small', {
      staticClass: "bold"
    }, [_vm._v(_vm._s(item.contact_phone))])]) : _vm._e(), _vm._showInTable.hvUid ? _c('td', {
      staticClass: "nobreak"
    }, _vm._l([_vm._fbHausverwaltungen[item.hvUid] ? _vm._fbHausverwaltungen[item.hvUid].name : '-'], function (hv) {
      return _c('span', [(hv || '').length > 18 ? _c('span', {
        directives: [{
          name: "tooltip",
          rawName: "v-tooltip",
          value: {
            content: hv,
            placement: 'top',
            offset: 10,
            delay: {
              show: 200,
              hide: 300
            }
          },
          expression: "{content: hv ,placement: 'top', offset:10, delay: {show: 200,hide: 300}}"
        }]
      }, [_vm._v(_vm._s(hv.substring(0, 18)) + "...")]) : _c('span', [_vm._v(_vm._s(hv))])]);
    }), 0) : _vm._e(), _vm._showInTable.fillByHvAt ? _c('td', {
      staticClass: "nobreak"
    }, [(item.fillByHvAt || 0) > 1546300800 * 1000 ? _c('span', [_vm._v(_vm._s(_vm.$dayjs(item.fillByHvAt).format(_vm.MAIN_DATE_FORMAT_CONDENSED)))]) : _vm._e()]) : _vm._e(), _vm._showInTable.contacts ? _c('td', {
      staticClass: "nobreak"
    }, [_c('span', [_vm._v(_vm._s((item.makler || {}).name))]), _c('br'), _c('small', [_vm._v(_vm._s((item.makler || {}).email))]), _c('br'), _c('small', {
      staticClass: "bold"
    }, [_vm._v(_vm._s((item.makler || {}).phone))])]) : _vm._e(), _vm._showInTable.contacts ? _c('td', {
      staticClass: "nobreak"
    }, [_c('span', [_vm._v(_vm._s((item.eigen || {}).name))]), _c('br'), _c('small', [_vm._v(_vm._s((item.eigen || {}).email))]), _c('br'), _c('small', {
      staticClass: "bold"
    }, [_vm._v(_vm._s((item.eigen || {}).phone))])]) : _vm._e(), _vm._showInTable.contacts ? _c('td', {
      staticClass: "nobreak"
    }, [_c('span', [_vm._v(_vm._s((item.besichtigung || {}).name))]), _c('br'), _c('small', [_vm._v(_vm._s((item.besichtigung || {}).email))]), _c('br'), _c('small', {
      staticClass: "bold"
    }, [_vm._v(_vm._s((item.besichtigung || {}).phone))])]) : _vm._e(), _vm._showInTable.vs_feuer ? _c('td', {
      staticClass: "nobreak"
    }, [_c('span', [_vm._v(_vm._s(_vm.$_formatMoney(item.vs_feuer)))]), _c('br'), _c('span', [_vm._v(_vm._s(_vm.$_formatMoney(item.summeNeu || '-')))])]) : _vm._e(), _vm._showInTable.svuid ? _c('td', {
      staticClass: "whitespace-pre"
    }, [_c('span', [_vm._v(_vm._s(item.resolvedsv))])]) : _vm._e(), _vm._showInTable.modified ? _c('td', [item.modified ? _c('div', {
      staticClass: "tar"
    }, [_c('span', [_vm._v(_vm._s(_vm.$dayjs(item.modified).format(_vm.MAIN_DATE_FORMAT_CONDENSED)))])]) : _vm._e()]) : _vm._e(), _vm._showInTable.reportedDataModified ? _c('td', [item.completedDate ? _c('div', {
      staticClass: "tar"
    }, [_c('span', [_vm._v(_vm._s(_vm.$dayjs(item.completedDate).format(_vm.MAIN_DATE_FORMAT_CONDENSED)) + " ")])]) : item.reportedDataModified ? _c('div', {
      staticClass: "tar"
    }, [_c('span', [_vm._v(_vm._s(_vm.$dayjs(item.reportedDataModified).format(_vm.MAIN_DATE_FORMAT_CONDENSED)) + " (B)")])]) : _vm._e()]) : _vm._e()]);
  }), 0) : _vm._e()]) : _vm.filteredListLength == 0 && _vm._currentUser.loadAllianz ? _c('div', {
    staticClass: "padding tac"
  }, [_c('h3', [_vm._v("Keine Daten vorhanden.")]), _c('div', {
    staticClass: "bold"
  }, [_vm._v("Bitte Filter/Suche anpassen")])]) : _vm._e(), _vm._currentUser.loadOnlyMy && _vm._currentUser.loadAllianz ? _c('div', {
    staticClass: "padding tac"
  }, [_c('h3', [_vm._v("Nur eigene Daten!")]), _c('span', [_vm._v("Bitte beachten, dass JETZT nur Daten, die IHNEN als SV zugeordnet sind hier angezeigt werden.")]), _c('br'), _c('span', [_vm._v("In den Einstellungen kann angeben werden, was geladen werden soll.")])]) : _vm._e(), _vm.filteredListLength == 0 && _vm._currentUser.loadOnlyMy && _vm._currentUser.loadAllianz ? _c('div', {
    staticClass: "padding tac"
  }, [_c('h3', [_vm._v("Keine Daten vorhanden!")]), _c('div', {
    staticClass: "bold"
  }, [_vm._v("Es sind Ihnen keine Daten zugeordnet!")])]) : _vm.filteredListLength == 0 ? _c('div', {
    staticClass: "padding tac"
  }, [_c('h3', [_vm._v("Keine Daten vorhanden!")]), _c('div', {
    staticClass: "bold"
  }, [_vm._v("Bitte Seite neu laden oder Filter/Suche anpassen")])]) : _vm._e(), _c('div', {
    staticClass: "clear"
  }), _c('div', {
    staticClass: "spacer _vertical"
  })])]) : _vm._e()]), _c('AllianzSelectTablePropsModal'), _c('AllianzXlsCsvExport', {
    attrs: {
      "filteredList": _vm.filteredList
    }
  }), _c('AllianzListBatch', {
    attrs: {
      "selectedArray": _vm.Selceted_Projects_Arr
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "padding"
  }, [_c('div', {
    staticClass: "tac"
  }, [_c('div', {
    staticClass: "loading-spinner _large"
  })])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('span', {
    staticClass: "link"
  }, [_c('i', {
    staticClass: "icon-magnifier _redp"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('span', {
    staticClass: "link"
  }, [_c('i', {
    staticClass: "icon-close _redp"
  })]);
}];
export { render, staticRenderFns };