import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "allianzlistbatch"
  }, [_c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "id": "MakeBatch",
      "name": "MakeBatch",
      "adaptive": true,
      "scrollable": true,
      "reset": true,
      "width": "960",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Sammelaktionen"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide("MakeBatch");
      }
    }
  }, [_c('i', {
    staticClass: "icon-times"
  })])]), _c('div', {
    staticClass: "block _batch"
  }, [_c('ul', {
    staticClass: "sidebarBatch"
  }, [_c('li', {
    staticClass: "tag bold",
    class: {
      _active: _vm.batch == 'hv'
    },
    on: {
      "click": function click($event) {
        _vm.batch = 'hv';
      }
    }
  }, [_vm._v("HV zuordnen")]), _c('li', {
    staticClass: "tag bold",
    class: {
      _active: _vm.batch == 'addtosv'
    },
    on: {
      "click": function click($event) {
        _vm.batch = 'addtosv';
      }
    }
  }, [_vm._v("SV zuordnen")]), _c('li', {
    staticClass: "tag bold",
    class: {
      _active: _vm.batch == 'status'
    },
    on: {
      "click": function click($event) {
        _vm.batch = 'status';
      }
    }
  }, [_vm._v("Status ändern")]), _c('li', {
    staticClass: "tag bold",
    class: {
      _active: _vm.batch == 'openlink'
    },
    on: {
      "click": function click($event) {
        _vm.batch = 'openlink';
      }
    }
  }, [_vm._v("Link öffnen/schließen")]), _c('li', [_vm._v("·")]), _c('li', {
    staticClass: "tag bold",
    class: {
      _active: _vm.batch == 'makler'
    },
    on: {
      "click": function click($event) {
        _vm.whichcon = 'makler', _vm.batch = 'makler';
      }
    }
  }, [_vm._v("Makler ändern")]), _c('li', {
    staticClass: "tag bold",
    class: {
      _active: _vm.batch == 'eigen'
    },
    on: {
      "click": function click($event) {
        _vm.whichcon = 'eigen', _vm.batch = 'eigen';
      }
    }
  }, [_vm._v("Eigentümer ändern")]), _c('li', {
    staticClass: "tag bold",
    class: {
      _active: _vm.batch == 'besichtigung'
    },
    on: {
      "click": function click($event) {
        _vm.whichcon = 'besichtigung', _vm.batch = 'besichtigung';
      }
    }
  }, [_vm._v("Besichtigung Kon.ändern")]), _c('li', {
    staticClass: "tag bold",
    class: {
      _active: _vm.batch == 'allianzakt'
    },
    on: {
      "click": function click($event) {
        _vm.batch = 'allianzakt';
      }
    }
  }, [_vm._v("Kontakt v.Allianz ändern")]), _c('li', {
    staticClass: "tag bold",
    class: {
      _active: _vm.batch == 'addresses'
    },
    on: {
      "click": function click($event) {
        _vm.batch = 'addresses';
      }
    }
  }, [_vm._v("Adressen Liste")])]), _c('div', {
    staticClass: "MainBatch"
  }, [_c('div', {
    staticClass: "bold"
  }, [_vm._v("Für die " + _vm._s(_vm.selectedArray.length) + " selektierten Akte folgende Sammelaktion durchführen:")])]), _vm.batch == 'hv' ? _c('div', {
    staticClass: "MainBatch"
  }, [_c('h5', [_vm._v("HVW zuordnen")]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("neue HV:")]), _c('div', {
    staticClass: "input"
  }, [_c('VueSelect', {
    attrs: {
      "label": "name",
      "options": _vm.$_O2A(_vm._fbHausverwaltungen),
      "reduce": function reduce(item) {
        return item.id;
      }
    },
    model: {
      value: _vm.Batch_NewHVWColletion,
      callback: function callback($$v) {
        _vm.Batch_NewHVWColletion = $$v;
      },
      expression: "Batch_NewHVWColletion"
    }
  })], 1)]), _vm.Batch_NewHVWColletion ? _c('div', [_c('v-checkbox', {
    attrs: {
      "label": "Link für Bearbeitung gleichzeitig für Bearbeitung öffnen"
    },
    model: {
      value: _vm.openForHvw,
      callback: function callback($$v) {
        _vm.openForHvw = $$v;
      },
      expression: "openForHvw"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "spacer _vertical"
  }), _vm.Batch_NewHVWColletion ? _c('span', [_c('div', {
    staticClass: "btn _m _green",
    on: {
      "click": function click($event) {
        return _vm.batchChangeHv();
      }
    }
  }, [_vm._v("Übernehmen")])]) : _vm._e()]) : _vm._e(), _vm.batch == 'makler' || _vm.batch == 'eigen' || _vm.batch == 'besichtigung' ? _c('div', {
    staticClass: "MainBatch"
  }, [_c('h5', [_vm._v("Kontakt ändern für: "), _c('span', {
    staticClass: "uppercase"
  }, [_vm._v(_vm._s(_vm.whichcon))])]), _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('td', [_vm._v("Ändern?")]), _c('td', [_vm._v("Feld")]), _c('td', [_vm._v("Inhalt")])])]), _c('tbody', [_c('tr', [_c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.contactBOOL[_vm.whichcon].name,
      expression: "contactBOOL[whichcon].name"
    }],
    staticClass: "input",
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.contactBOOL[_vm.whichcon].name) ? _vm._i(_vm.contactBOOL[_vm.whichcon].name, null) > -1 : _vm.contactBOOL[_vm.whichcon].name
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.contactBOOL[_vm.whichcon].name,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.contactBOOL[_vm.whichcon], "name", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.contactBOOL[_vm.whichcon], "name", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.contactBOOL[_vm.whichcon], "name", $$c);
        }
      }
    }
  })]), _c('td', [_vm._v("Name "), _c('span', {
    staticClass: "uppercase"
  }, [_vm._v(_vm._s(_vm.whichcon))])]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.contact[_vm.whichcon].name,
      expression: "contact[whichcon].name"
    }],
    staticClass: "input _large",
    domProps: {
      "value": _vm.contact[_vm.whichcon].name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.contact[_vm.whichcon], "name", $event.target.value);
      }
    }
  })])]), _c('tr', [_c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.contactBOOL[_vm.whichcon].email,
      expression: "contactBOOL[whichcon].email"
    }],
    staticClass: "input",
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.contactBOOL[_vm.whichcon].email) ? _vm._i(_vm.contactBOOL[_vm.whichcon].email, null) > -1 : _vm.contactBOOL[_vm.whichcon].email
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.contactBOOL[_vm.whichcon].email,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.contactBOOL[_vm.whichcon], "email", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.contactBOOL[_vm.whichcon], "email", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.contactBOOL[_vm.whichcon], "email", $$c);
        }
      }
    }
  })]), _c('td', [_vm._v("Email "), _c('span', {
    staticClass: "uppercase"
  }, [_vm._v(_vm._s(_vm.whichcon))])]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.contact[_vm.whichcon].email,
      expression: "contact[whichcon].email"
    }],
    staticClass: "input _large",
    domProps: {
      "value": _vm.contact[_vm.whichcon].email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.contact[_vm.whichcon], "email", $event.target.value);
      }
    }
  })])]), _c('tr', [_c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.contactBOOL[_vm.whichcon].phone,
      expression: "contactBOOL[whichcon].phone"
    }],
    staticClass: "input",
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.contactBOOL[_vm.whichcon].phone) ? _vm._i(_vm.contactBOOL[_vm.whichcon].phone, null) > -1 : _vm.contactBOOL[_vm.whichcon].phone
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.contactBOOL[_vm.whichcon].phone,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.contactBOOL[_vm.whichcon], "phone", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.contactBOOL[_vm.whichcon], "phone", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.contactBOOL[_vm.whichcon], "phone", $$c);
        }
      }
    }
  })]), _c('td', [_vm._v("Telefon "), _c('span', {
    staticClass: "uppercase"
  }, [_vm._v(_vm._s(_vm.whichcon))])]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.contact[_vm.whichcon].phone,
      expression: "contact[whichcon].phone"
    }],
    staticClass: "input _large",
    domProps: {
      "value": _vm.contact[_vm.whichcon].phone
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.contact[_vm.whichcon], "phone", $event.target.value);
      }
    }
  })])])])]), _c('div', {
    staticClass: "btn _m _green",
    on: {
      "click": function click($event) {
        return _vm.batchChangeContact(_vm.whichcon);
      }
    }
  }, [_vm._v("Übernehmen")])]) : _vm._e(), _vm.batch == 'openlink' ? _c('div', {
    staticClass: "MainBatch"
  }, [_c('h5', [_vm._v("Link für Bearbeitung öffnen")]), _c('span', [_c('div', {
    staticClass: "input"
  }, [_c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.openForHvw,
      expression: "openForHvw"
    }],
    attrs: {
      "type": "radio",
      "id": "one"
    },
    domProps: {
      "value": true,
      "checked": _vm._q(_vm.openForHvw, true)
    },
    on: {
      "change": function change($event) {
        _vm.openForHvw = true;
      }
    }
  }), _c('label', {
    attrs: {
      "for": "one"
    }
  }, [_vm._v(" Öffnen")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.openForHvw,
      expression: "openForHvw"
    }],
    attrs: {
      "type": "radio",
      "id": "two"
    },
    domProps: {
      "value": false,
      "checked": _vm._q(_vm.openForHvw, false)
    },
    on: {
      "change": function change($event) {
        _vm.openForHvw = false;
      }
    }
  }), _c('label', {
    attrs: {
      "for": "two"
    }
  }, [_vm._v(" Schließen")]), _c('br'), _c('br')])]), _c('div', {
    staticClass: "btn _m _green",
    on: {
      "click": function click($event) {
        return _vm.batchOpenForAll();
      }
    }
  }, [_vm._v("Übernehmen")])]) : _vm._e(), _vm.batch == 'allianzakt' ? _c('div', {
    staticClass: "MainBatch"
  }, [_c('h5', [_vm._v("Allianz Grunddaten ändern")]), _c('br'), _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('td', [_vm._v("Ändern?")]), _c('td', [_vm._v("Feld")]), _c('td', [_vm._v("Inhalt")])])]), _c('tbody', [_c('tr', [_c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzAktBOOL.vorname,
      expression: "allianzAktBOOL.vorname"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.allianzAktBOOL.vorname) ? _vm._i(_vm.allianzAktBOOL.vorname, null) > -1 : _vm.allianzAktBOOL.vorname
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.allianzAktBOOL.vorname,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.allianzAktBOOL, "vorname", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.allianzAktBOOL, "vorname", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.allianzAktBOOL, "vorname", $$c);
        }
      }
    }
  })]), _c('td', [_vm._v("vorname")]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzAkt.vorname,
      expression: "allianzAkt.vorname"
    }],
    staticClass: "large",
    domProps: {
      "value": _vm.allianzAkt.vorname
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.allianzAkt, "vorname", $event.target.value);
      }
    }
  })])]), _c('tr', [_c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzAktBOOL.name,
      expression: "allianzAktBOOL.name"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.allianzAktBOOL.name) ? _vm._i(_vm.allianzAktBOOL.name, null) > -1 : _vm.allianzAktBOOL.name
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.allianzAktBOOL.name,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.allianzAktBOOL, "name", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.allianzAktBOOL, "name", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.allianzAktBOOL, "name", $$c);
        }
      }
    }
  })]), _c('td', [_vm._v("name")]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzAkt.name,
      expression: "allianzAkt.name"
    }],
    staticClass: "large",
    domProps: {
      "value": _vm.allianzAkt.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.allianzAkt, "name", $event.target.value);
      }
    }
  })])]), _c('tr', [_c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzAktBOOL.contact_name,
      expression: "allianzAktBOOL.contact_name"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.allianzAktBOOL.contact_name) ? _vm._i(_vm.allianzAktBOOL.contact_name, null) > -1 : _vm.allianzAktBOOL.contact_name
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.allianzAktBOOL.contact_name,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.allianzAktBOOL, "contact_name", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.allianzAktBOOL, "contact_name", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.allianzAktBOOL, "contact_name", $$c);
        }
      }
    }
  })]), _c('td', [_vm._v("contact_name")]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzAkt.contact_name,
      expression: "allianzAkt.contact_name"
    }],
    staticClass: "large",
    domProps: {
      "value": _vm.allianzAkt.contact_name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.allianzAkt, "contact_name", $event.target.value);
      }
    }
  })])]), _c('tr', [_c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzAktBOOL.contact_email,
      expression: "allianzAktBOOL.contact_email"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.allianzAktBOOL.contact_email) ? _vm._i(_vm.allianzAktBOOL.contact_email, null) > -1 : _vm.allianzAktBOOL.contact_email
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.allianzAktBOOL.contact_email,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.allianzAktBOOL, "contact_email", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.allianzAktBOOL, "contact_email", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.allianzAktBOOL, "contact_email", $$c);
        }
      }
    }
  })]), _c('td', [_vm._v("contact_email")]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzAkt.contact_email,
      expression: "allianzAkt.contact_email"
    }],
    staticClass: "large",
    domProps: {
      "value": _vm.allianzAkt.contact_email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.allianzAkt, "contact_email", $event.target.value);
      }
    }
  })])]), _c('tr', [_c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzAktBOOL.contact_phone,
      expression: "allianzAktBOOL.contact_phone"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.allianzAktBOOL.contact_phone) ? _vm._i(_vm.allianzAktBOOL.contact_phone, null) > -1 : _vm.allianzAktBOOL.contact_phone
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.allianzAktBOOL.contact_phone,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.allianzAktBOOL, "contact_phone", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.allianzAktBOOL, "contact_phone", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.allianzAktBOOL, "contact_phone", $$c);
        }
      }
    }
  })]), _c('td', [_vm._v("contact_phone")]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzAkt.contact_phone,
      expression: "allianzAkt.contact_phone"
    }],
    staticClass: "large",
    domProps: {
      "value": _vm.allianzAkt.contact_phone
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.allianzAkt, "contact_phone", $event.target.value);
      }
    }
  })])])])]), _c('div', {
    staticClass: "btn _m _green",
    on: {
      "click": function click($event) {
        return _vm.batchChangeAllianzData();
      }
    }
  }, [_vm._v("Übernehmen")])]) : _vm._e(), _vm.batch == 'addtosv' ? _c('div', {
    staticClass: "MainBatch"
  }, [_c('h5', [_vm._v("SV hinzufügen")]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("neuer SV:")]), _c('div', {
    staticClass: "input"
  }, [_c('VueSelect', {
    attrs: {
      "label": "name",
      "options": _vm._fbUsersSelectingArr,
      "reduce": function reduce(item) {
        return item.id;
      }
    },
    model: {
      value: _vm.Batch_NewSV,
      callback: function callback($$v) {
        _vm.Batch_NewSV = $$v;
      },
      expression: "Batch_NewSV"
    }
  })], 1)]), _c('br'), _vm.Batch_NewSV ? _c('span', [_c('div', {
    staticClass: "btn _m _green",
    on: {
      "click": function click($event) {
        return _vm.batchAssignToSv();
      }
    }
  }, [_vm._v("Für SV1 Übernehmen")])]) : _vm._e(), _vm.Batch_NewSV ? _c('span', [_c('div', {
    staticClass: "btn _m _green",
    on: {
      "click": function click($event) {
        return _vm.batchAssignToSv2();
      }
    }
  }, [_vm._v("Für SV2 Übernehmen")])]) : _vm._e()]) : _vm._e(), _vm.batch == 'status' ? _c('div', {
    staticClass: "MainBatch"
  }, [_c('h5', [_vm._v("Status ändern")]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("neuer Status:")]), _c('div', {
    staticClass: "input"
  }, [_c('VueSelect', {
    attrs: {
      "label": "name",
      "options": _vm.$_O2A(_vm._fbStatus.aktStatus),
      "reduce": function reduce(item) {
        return item.id;
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(option) {
        return [_c('span', {
          staticClass: "bold"
        }, [_vm._v(_vm._s(option.icon) + " ")]), _c('span', [_vm._v(_vm._s(option.name))])];
      }
    }], null, false, 2319901329),
    model: {
      value: _vm.Batch_NewStatus,
      callback: function callback($$v) {
        _vm.Batch_NewStatus = $$v;
      },
      expression: "Batch_NewStatus"
    }
  })], 1)]), _c('br'), _vm.Batch_NewStatus ? _c('span', [_c('div', {
    staticClass: "btn _m _green",
    on: {
      "click": function click($event) {
        return _vm.batchChangeStatus();
      }
    }
  }, [_vm._v("Übernehmen")])]) : _vm._e()]) : _vm._e(), _vm.batch == 'addresses' ? _c('div', {
    staticClass: "MainBatch"
  }, [_c('h5', [_vm._v("Adressen Liste")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.addresslist,
      expression: "addresslist"
    }],
    staticClass: "addresses",
    attrs: {
      "disabled": ""
    },
    domProps: {
      "value": _vm.addresslist
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.addresslist = $event.target.value;
      }
    }
  })]) : _vm._e()]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "btn _m _red",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide("MakeBatch");
      }
    }
  }, [_vm._v("Schließen")])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };