import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "select-table-props-modal"
  }, [_c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "id": "SetTableProps",
      "name": "SetTableProps",
      "adaptive": true,
      "draggable": false,
      "scrollable": true,
      "resizable": true,
      "reset": true,
      "width": "640",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Tabelle anpassen:"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('SetTableProps');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _c('div', {
    staticClass: "block"
  }, [_c('ul', _vm._l(_vm.showInAllianzTableLocal, function (s) {
    return s.roles.length === 0 || s.roles.includes(_vm._userRole) ? _c('li', [_c('span', {
      staticClass: "minwidth160px"
    }, [_vm._v(_vm._s(s.name) + ":")]), _c('toggle-button', {
      attrs: {
        "color": "#82C7EB",
        "sync": true,
        "labels": {
          checked: 'JA',
          unchecked: 'NEIN'
        }
      },
      model: {
        value: s.value,
        callback: function callback($$v) {
          _vm.$set(s, "value", $$v);
        },
        expression: "s.value"
      }
    })], 1) : _vm._e();
  }), 0)])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };