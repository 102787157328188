import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.keys.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "xls-csv-export"
  }, [_c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "id": "CSVExportModal",
      "name": "CSVExportModal",
      "adaptive": true,
      "draggable": false,
      "scrollable": true,
      "resizable": true,
      "reset": true,
      "width": "640",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("XLSX/CSV Export"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('CSVExportModal');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _c('div', {
    staticClass: "block padding"
  }, [_c('p', [_vm._v("Es werden alle Akte exportiert, die angezeit werden, also die gefiltert/gesucht sind.")]), _c('br'), _vm.isWhichExportFilter ? _c('span', [_c('VueSelect', {
    attrs: {
      "label": "name",
      "options": _vm.O2A(_vm.status)
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(option) {
        return [_c('span', {
          staticClass: "bold"
        }, [_vm._v(_vm._s(option.icon))]), _c('span', [_vm._v(_vm._s(option.name))])];
      }
    }], null, false, 523672666),
    model: {
      value: _vm.whichExportFilter,
      callback: function callback($$v) {
        _vm.whichExportFilter = $$v;
      },
      expression: "whichExportFilter"
    }
  }), _c('br')], 1) : _vm._e(), _c('div', {
    staticClass: "clear"
  }), _c('div', {
    staticClass: "btn _s _red",
    on: {
      "click": function click($event) {
        _vm.chosenfields = Object.keys(_vm.fieldTranslator);
      }
    }
  }, [_vm._v("Alle markieren")]), _c('div', {
    staticClass: "btn _s _red",
    on: {
      "click": function click($event) {
        _vm.chosenfields = [];
      }
    }
  }, [_vm._v("Alle demarkieren")]), _c('div', {
    staticClass: "clear"
  }), _c('br'), _c('div', {
    staticClass: "clear"
  }), _c('hr'), _c('br'), _c('div', {
    staticClass: "flex-wrapper padding"
  }, [_c('ul', {
    staticClass: "flexlist"
  }, _vm._l(_vm.fieldTranslator, function (field, idx) {
    return _c('li', [_c('v-checkbox', {
      attrs: {
        "item": idx,
        "label": field
      },
      model: {
        value: _vm.chosenfields,
        callback: function callback($$v) {
          _vm.chosenfields = $$v;
        },
        expression: "chosenfields"
      }
    })], 1);
  }), 0)])]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "btn _med _red",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('CSVExportModal');
      }
    }
  }, [_vm._v("Abbrechen")]), _c('div', {
    staticClass: "btn _med _green",
    on: {
      "click": function click($event) {
        return _vm.ExportCSV(_vm.chosenfields, 'csv');
      }
    }
  }, [_vm._v("EXPORTIEREN CSV")]), _c('div', {
    staticClass: "btn _med _green",
    on: {
      "click": function click($event) {
        return _vm.ExportCSV(_vm.chosenfields, 'xlsx');
      }
    }
  }, [_vm._v("EXPORTIEREN XLSX")])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };